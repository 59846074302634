<template>
  <div class="wrap">
    <div class="facilityBox">
      <!-- form表单 -->
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <el-form-item label="企业名称：" prop="EnterpriseFullName">
          <el-input
            class="fromInp"
            v-model="manageForm.EnterpriseFullName"
            placeholder="输入企业名称"
            @keyup.enter.native="search()"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="LegalPersonlPhone">
          <el-input
            class="fromInp"
            v-model="manageForm.LegalPersonlPhone"
            placeholder="输入手机号"
            @keyup.enter.native="search()"
          ></el-input>
        </el-form-item>
        <el-form-item label="提交日期：" prop="TaskDatetime">
          <el-date-picker
            v-model="manageForm.TaskDatetime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期"
            @change="search()"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="medium"
            @click="search()"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button
            type="primary"
            size="medium"
            @click="resetForm()"
            icon="el-icon-delete"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseFullName"
          label="企业名称"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LegalPersonIName"
          label="姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseCreditCode"
          label="企业统一社会信用代码"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseAddress"
          label="企业地址"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="CreatedTime"
          label="提交时间"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="UpdatedTime"
          label="变更时间"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="StatusName"
          label="状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.StatusName }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="Remark" label="备注" width="200" fixed="right" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-alert :title="scope.row.Remark" type="warning" :closable="false" v-if="scope.row.Remark"></el-alert>
          </template>
        </el-table-column> -->
        <el-table-column align="center" label="操作" fixed="right" width="350">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="examineRate(scope.row)"
              icon="el-icon-setting"
              >查看费率
            </el-button>
            <el-button
              type="primary"
              size="small"
              icon="el-icon-share"
              @click="examineRate(scope.row, 'examine')"
            >
              审核</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="goFacility(scope.row, pagination.page)"
              icon="el-icon-tickets"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 驳回弹窗 -->
    <el-dialog
      title="驳回意见"
      :visible.sync="flag.centerDialogVisible"
      width="30%"
      center
    >
      <div style="margin: 20px 0 0 0">
        <el-input
          type="textarea"
          :rows="6"
          maxlength="100"
          show-word-limit
          placeholder="请输入驳回处理意见，限制100字。"
          v-model="textarea"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flag.centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="bindBack()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看费率 -->
    <el-dialog
      :visible.sync="flag.rateMdel"
      width="50%"
      :title="operateType == 'examine' ? '审核费率' : '查看费率'"
      v-if="flag.rateMdel"
      class="deep_dialog"
    >
      <el-tabs v-model="rateType" style="margin-top: 1vh">
        <el-tab-pane label="费率" name="common"></el-tab-pane>
        <el-tab-pane label="专项费率" name="special"></el-tab-pane>
      </el-tabs>
      <div v-if="rateType == 'common'">
        <div style="font-weight: bold; text-align: center; margin-bottom: 20px">
          企业名称：{{ serveInfo.EnterpriseFullName }}
        </div>
        <el-table
          :data="rateData"
          :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
          v-loading="loading"
          :key="rateType"
          max-height="500px"
        >
          <el-table-column align="center" label="发票-业务-场景">
            <template slot-scope="scope">
              <span v-if="scope.row.TaskSceneName">
                {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} /
                {{ scope.row.TaskSceneName }}</span
              >
              <span v-else> {{ scope.row.InvoiceTypeName }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="数值" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.Rate ? scope.row.Rate + "%" : scope.row.Rate }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="toDetail(scope.row)"
                :disabled="!scope.row.RateID"
                >查看历史记录</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <el-table
          :data="extSupplierList"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
          max-height="450px"
          :key="rateType"
        >
          <el-table-column
            align="center"
            label="企业名称"
            show-overflow-tooltip
            width="230"
            ><template slot-scope="scope">{{
              scope.row.EnterpriseFullName
            }}</template></el-table-column
          >
          <el-table-column
            align="center"
            label="状态"
            show-overflow-tooltip
            width="100"
          >
            <template slot-scope="scope">
              <el-tag
                :type="
                  scope.row.Status == 10
                    ? 'primary'
                    : scope.row.Status == 1
                    ? 'danger'
                    : 'success'
                "
                >{{ scope.row.StatusName }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="备注"
            show-overflow-tooltip
            width="200"
            ><template slot-scope="scope">{{
              scope.row.Remark
            }}</template></el-table-column
          >
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <!-- <el-button type="success" size="small" @click="agreeClick(scope.row)"
                v-if="(scope.row.Status == 10 || scope.row.Status == 1) && operateType == 'examine'">同意</el-button> -->
              <el-button
                type="danger"
                size="small"
                @click="backClick(scope.row, 'special')"
                v-if="
                  scope.row.Status != 0 &&
                  scope.row.Status != 1 &&
                  operateType == 'examine'
                "
                >退回重设</el-button
              >
              <el-button
                type="primary"
                size="small"
                @click="watchRate(scope.row)"
                >查看费率</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <!-- 分页 -->
          <el-pagination
            background
            class="pagination"
            @current-change="handleCurrentChange1"
            :current-page.sync="pagination1.page"
            :page-size="pagination1.pagesize"
            layout="total, prev, pager, next, jumper"
            :total="pagination1.total"
          >
          </el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          size="small"
          @click="backClick(null, 'common')"
          v-if="rateType == 'common' && operateType == 'examine'"
          >退回重设</el-button
        >
        <el-button @click="flag.rateMdel = false" size="small">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 查看企业专项费率 -->
    <el-dialog :visible.sync="itemRateDialog" width="50%">
      <div style="font-weight: bold; text-align: center; margin-bottom: 20px">
        企业名称：{{ itemInfo.EnterpriseFullName }}
      </div>
      <el-table
        :data="itemRateList"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        max-height="450px"
      >
        <el-table-column
          align="center"
          prop="EnterpriseAddress"
          label="发票-业务-场景"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.TaskSceneName">
              {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} /
              {{ scope.row.TaskSceneName }}</span
            >
            <span v-else> {{ scope.row.InvoiceTypeName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="数值">
          <template slot-scope="scope">{{
            scope.row.Rate ? scope.row.Rate + "%" : scope.row.Rate
          }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 查看费率历史记录 -->
    <el-dialog
      title="历史记录"
      :visible.sync="rateRecordDialog"
      width="40%"
      v-if="rateRecordDialog"
      class="rate-dialog"
    >
      <div style="text-align: center; font-weight: bold; margin-bottom: 10px">
        发票-业务-场景：
        <span v-if="currentItem.TaskSceneName">
          {{ currentItem.InvoiceTypeName }} / {{ currentItem.TaskTypeName }} /
          {{ currentItem.TaskSceneName }}</span
        >
        <span v-else> {{ currentItem.InvoiceTypeName }}</span>
      </div>
      <el-table
        :data="rateRecord"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading="loading"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Rate"
          label="费率"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            scope.row.Rate ? scope.row.Rate + "%" : ""
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CteatedTime"
          label="变更时间"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange2"
          :current-page.sync="pagination2.page"
          :page-size="pagination2.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination2.total"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  AgentList,
  RejectRate,
  GetAgentRate,
  GetAgentExtSupplier,
  GetAgentExtRate,
  GetRateRecords,
  AgreeRate,
} from "@/api/newRate";
export default {
  props: {
    type: "",
  },
  data() {
    return {
      // 分页参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination1: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination2: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      },
      // 数据列表
      tableData: [],
      // 弹窗集合
      flag: {
        // 是否显示退回意见弹窗
        centerDialogVisible: false,
        // 查看费率
        rateMdel: false,
      },
      // 退回意见文本
      textarea: "",
      // 表格loading
      loading: true,
      // 服务商信息
      serveInfo: {},
      // 费率信息
      rateData: [],
      rateType: "common",
      extSupplierList: [],
      itemRateList: [],
      itemInfo: {},
      itemRateDialog: false,
      operateType: "",
      currentItem: "",
      rateRecordDialog: false,
      rateRecord: [],
      backType: "",
    };
  },
  activated() {
    // 获取列表数据
    this.ByAgentLists();
  },
  created() {
    // 获取数据列表
    // this.ByAgentLists();
  },
  methods: {
    // 查看费率
    examineRate(item, type) {
      this.rateType = "common";
      this.operateType = type;
      this.serveInfo = item;
      GetAgentRate({ userid: item.UserID }).then((res) => {
        let ratelist = [];
        if (res.data && res.data.length) {
          res.data.forEach((item) => {
            item.Rate = item.Rate == 0 || item.Rate == null ? "" : item.Rate;
            ratelist.push(item);
          });
        }
        this.rateData = ratelist;
        this.flag.rateMdel = true;
      });
      this.getAgentExtSupplier();
    },
    // 获取专项费率列表
    getAgentExtSupplier() {
      GetAgentExtSupplier({
        pageSize: this.pagination1.pagesize,
        pageIndex: this.pagination1.page,
        userID: this.serveInfo.UserID,
        enterpriseFullName: "",
      }).then((res) => {
        this.extSupplierList = res.data.DataList;
        this.pagination1.total = res.data.TotalCount;
      });
    },
    watchRate(item) {
      this.itemInfo = item;
      this.loading = true;
      GetAgentExtRate({
        userID: this.serveInfo.UserID,
        partnerUserID: item.UserID,
      })
        .then((res) => {
          let ratelist = [];
          if (res.data && res.data.length) {
            res.data.forEach((item) => {
              item.Rate = item.Rate == 0 || item.Rate == null ? "" : item.Rate;
              ratelist.push(item);
            });
          }
          this.itemRateList = ratelist;
          this.itemRateDialog = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查看历史记录
    toDetail(item) {
      this.currentItem = item;
      this.getRateRecords();
    },
    getRateRecords() {
      this.loading = true;
      GetRateRecords({
        pageSize: this.pagination2.pagesize,
        pageIndex: this.pagination2.page,
        rateID: this.currentItem.RateID,
      })
        .then((res) => {
          this.rateRecord = res.data.DataList;
          this.pagination2.total = res.data.TotalCount;
          this.rateRecordDialog = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 同意专项费率
    agreeClick(item) {
      this.itemInfo = item;
      let data = {
        optionID: item.OptionID,
      };
      AgreeRate(data).then((res) => {
        this.$message.success("操作成功");
        this.getAgentExtSupplier();
      });
    },
    // 提交退回意见
    bindBack() {
      if (this.textarea) {
        let data = {
          optionID:
            this.backType == "special"
              ? this.itemInfo.OptionID
              : this.serveInfo.OptionID,
          remark: `驳回意见：${this.textarea}`,
        };
        RejectRate(data).then((res) => {
          if (this.backType == "special") {
            this.getAgentExtSupplier();
          } else {
            this.search();
            this.flag.rateMdel = false;
          }
          this.textarea = "";
          this.$message.success("操作成功");
          this.flag.centerDialogVisible = false;
        });
      } else {
        this.$message.error("请输入驳回意见");
      }
    },
    // 退回重设
    backClick(item, type) {
      this.backType = type;
      if (item) {
        this.itemInfo = item;
      }
      this.textarea = "";
      this.flag.centerDialogVisible = true;
    },
    // 清空搜索条件
    resetForm() {
      this.manageForm = {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      };
      this.search();
    },
    // 搜索
    search() {
      this.pagination.page = 1;
      this.ByAgentLists();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.ByAgentLists();
    },
    handleCurrentChange1(e) {
      this.pagination1.page = e;
      this.getAgentExtSupplier();
    },
    handleCurrentChange2(e) {
      this.pagination2.page = e;
      this.getRateRecords();
    },
    // 设置服务商分成比例列表
    ByAgentLists() {
      this.loading = true;
      let data = {
        enterpriseFullName: this.manageForm.EnterpriseFullName,
        legalPersonlPhone: this.manageForm.LegalPersonlPhone,
        createdTimeStart:
          this.manageForm.TaskDatetime && this.manageForm.TaskDatetime[0]
            ? this.manageForm.TaskDatetime[0] + " 00:00:00"
            : "",
        createdTimeEnd:
          this.manageForm.TaskDatetime && this.manageForm.TaskDatetime[1]
            ? this.manageForm.TaskDatetime[1] + " 23:59:59"
            : "",
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        status: this.type == "agree" ? 20 : 100, // 'agree'是已同意20，'cooper'是已合作100
      };
      AgentList(data)
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = res.data.TotalCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查看详情
    goFacility(item, pageIndex) {
      this.$router.push({
        // path: "/auditInfo/auditAgency/agreeAgencyInfo", 功能重复,跳转到下面的路径去
        path: "/auditInfo/auditAgency/agencyInfo",
        query: {
          UserID: item.UserID,
          pageIndex,
          title: this.type == "agree" ? "同意" : "合作",
          isWait: 0,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.wrap {
  .el-input.is-disabled .el-input__inner {
    color: #333 !important;
  }
}
</style>
<style lang="scss" scoped>
.mdoeBox {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 20px 0 0 0;
}

.mdoeDiv {
  font-size: 16px;
  width: 80%;
  margin: 10px 10% 0;
  font-weight: bold;
  color: red;
}

.dialog_box {
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  background: #f8f8f8;
  padding: 20px 5%;
  box-sizing: border-box;
  color: #666;
  border-radius: 5px;

  .dialogRole {
    width: 100%;
    text-align: center;
  }
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 0px 20px 20px;
  }
}
</style>
